import { ReactElement, useMemo, useState } from "react";
import { Box, FormControl, FormHelperText, FormLabel, Typography } from "@mui/material";
import { Controller, useForm, UseFormReturn } from "react-hook-form";
import { CabAutocomplete, CabButton, CabTextInput, CabModal, CabIcon, CabAvatar } from "@CabComponents";
import { AllOAuthGrantDetails, Calendar, Leader, User } from "../../../store";
import StepHeader from "../StepHeader";
import colors from "../../../colors";
import { uniqBy } from "lodash-es";
import SearchMissingCalendars from "../../Schedule/SearchMissingCalendars";
import CabSpinner from "@CabComponents/CabSpinner";
import { USER_ROLE, emailRegex } from "../../../constants";
import { checkForCalendarGrants } from "../../../utils/authUtils";
import { IoCalendarClearOutline, IoTrash, IoAdd, IoArrowForward } from "react-icons/io5";


export interface ExecutiveCalendarStepProps {
  user: User;
  leaders: Leader[];
  oauth_grant_details: AllOAuthGrantDetails;
  calendars: Calendar[];
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>;
  onAddEditExec: (leader: {
    id?: Leader['id'], firstName: string, lastName: string, email: string,
    calendars: { id: number; associate: boolean }[]
  }) => Promise<void>;
  onDeleteExec: (id: Leader['id']) => Promise<void>;
  onFinish: () => Promise<void>;
  buttonText?: string;
}

interface FormInput {
  firstName: string;
  lastName: string;
  email: string;
  calendars: number[];
  isUser: boolean;
}

const ExecutiveListItem = ({ executive, onEdit, onDelete }: {
  executive: FormInput, onEdit?: () => void, onDelete?: () => void
}) => (
  <Box display="flex" flexDirection="row" width="100%" alignItems="center" padding={1}
    flex={1} borderColor={colors.black300}
    sx={{ borderWidth: 1, borderStyle: 'solid', borderRadius: 1, backgroundColor: colors.white900 }}
  >
    <Box display="flex" flexDirection="row" gap={2} width="100%" alignItems="center" padding={2}>
      <CabAvatar name={`${executive.firstName} ${executive.lastName}`} color="royalblue" />
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography fontWeight={700}>{executive.firstName} {executive.lastName}</Typography>
        <Box display="flex" flexDirection="row" gap={1}>
          <CabIcon Icon={IoCalendarClearOutline} size="small" />
          <Typography variant="body2">
            {executive.calendars.length} Calendar{executive.calendars.length !== 1 ? 's' : ''} Linked
          </Typography>
        </Box>
      </Box>
    </Box>

    {onEdit && (
      <Box display="flex" flexDirection="row">
        <CabButton
          onClick={onEdit}
          buttonType="secondary"
          sx={{ width: 170, height: 36, backgroundColor: colors.black200, color: colors.black900 }}
        >
          {executive.isUser ? 'Edit My Calendars' : 'Edit Executive'}
        </CabButton>
        {onDelete ? (
          <CabButton buttonType="text" onClick={onDelete} icon={<CabIcon Icon={IoTrash} />} />
        ) : (
          <Box width={64} />
        )}
      </Box>
    )}
  </Box>
);

interface AddEditExecutiveProps {
  form: UseFormReturn<FormInput>;
  calendars: Calendar[];
  onGetEmailCalendars: (email: string) => Promise<Calendar[]>;
  oauth_grant_details: AllOAuthGrantDetails;
  hideName?: boolean;
  user: User;
}

const AddEditExecutive = ({ 
  form, calendars, onGetEmailCalendars, oauth_grant_details, hideName, user
}: AddEditExecutiveProps) => {
  const { control, formState: { errors } } = form;
  
  const [additionalCalendars, setAdditionalCalendars] = useState<{ [eml: string]: Calendar[] }>({});

  const hasCalendarGrants = useMemo(() => (
    checkForCalendarGrants(oauth_grant_details)
  ), [oauth_grant_details]);

  const handleSetAdditionalCalendars = (value: { [eml: string]: Calendar[] }) => 
    setAdditionalCalendars(value);

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {!hideName && (
        <>
          <Box display="flex" flexDirection="row" gap={2}>
            <FormControl sx={{ flex: 1 }}>
              <FormLabel>First Name*</FormLabel>
              <Controller name="firstName" control={control} rules={{ required: true }} 
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} />
                )}
              />
              <FormHelperText error>{errors?.firstName && 'First name is required'}</FormHelperText>
            </FormControl>

            <FormControl sx={{ flex: 1 }}>
              <FormLabel>Last Name*</FormLabel>
              <Controller name="lastName" control={control} rules={{ required: true }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} />
                )}
              />
              <FormHelperText error>{errors?.lastName && 'Last name is required'}</FormHelperText>
            </FormControl>
          </Box>
          <Box>
            <FormControl fullWidth>
              <FormLabel>Email for Calendar Invites*</FormLabel>
              <Controller name="email" control={control} rules={{ required: true, pattern: emailRegex }}
                render={({ field: { ref, ...field } }) => (
                  <CabTextInput {...field} type='email' />
                )}
              />
              {errors.email && (
                <FormHelperText error>
                  {errors.email.message
              || (errors?.email?.type === 'required' ? 'Email is required'
                : errors.email?.type === 'pattern' ? 'Please enter a valid email address'
                  : null)}
                </FormHelperText>
              )}
              <FormHelperText>
                Don't panic! Your Exec won't get spammed. This is only for calendar invites.
              </FormHelperText>
            </FormControl>
          </Box>
        </>
      )}
      {hasCalendarGrants &&
        <>
          <FormControl fullWidth>
            {hideName &&  user.active_license.user_role !== USER_ROLE.INDIVIDUAL && (
              <Box display='flex' flexDirection='column' gap={2} marginBottom={2}>
                <Typography>
                  In this step, attach only the calendars you use for <b>yourself</b>, not for your executives. 
                </Typography>
                <Typography>
                  To support your executives with Cabinet, you can add them using the "+ Add New Executive"  
                  button after adding your calendars. 
                </Typography>
              </Box>
            )}
            <FormLabel>
              {!hideName ? `Attach this Executive's Calendar(s)` : `Attach your Calendars`}
            </FormLabel>
            <Controller name="calendars" control={control} render={({ field: { ref, ...field } }) => (
              <CabAutocomplete<number, never>
                {...field}
                placeholder="Select a calendar"
                multiple
                sx={{ width: 'auto' }}
                options={uniqBy(
                  [...Object.values(additionalCalendars).flat(), ...calendars],
                  cal => cal.id
                ).map((calendar) => ({
                  value: calendar.id,
                  label: calendar.additionalCalendarEmail ? calendar.additionalCalendarEmail.name : calendar.summary,
                  color: calendar.backgroundColor
                }))}
              />
            )} />
          </FormControl>
          {Object.keys(oauth_grant_details["microsoft"]).length > 0 && !hideName &&
            <SearchMissingCalendars
              additionalCalendars={additionalCalendars}
              handleSetAdditionalCalendars={handleSetAdditionalCalendars }
              onGetEmailCalendars={onGetEmailCalendars}
            />
          }
        </>
      }
    </Box>
  );
};

const ExecutiveCalendarStep = ({
  user, leaders, calendars, onAddEditExec, onDeleteExec, onFinish, onGetEmailCalendars,
  oauth_grant_details, buttonText
}: ExecutiveCalendarStepProps): ReactElement => {
  const execForm = useForm<FormInput>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      calendars: [],
    },
  });

  const { reset, formState: { isSubmitting, isValid }, handleSubmit: handleFormSubmit } = execForm;

  const [addingExec, setAddingExec] = useState(false);
  const [editingExec, setEditingExec] = useState<Leader['id']|null>(null);
  const [loading, setLoading] = useState(false);

  const hasCalendarGrants = useMemo(() => (
    checkForCalendarGrants(oauth_grant_details)
  ), [oauth_grant_details]);

  const handleEdit = (leaderId: Leader['id'], leaderInfo: FormInput) => {
    reset(leaderInfo);
    setEditingExec(leaderId);
  };

  const handleSave = async (value: FormInput & { id?: Leader['id'] }) => {
    const { calendars: assignedCalendars, ...newValues } = value;
    const prevCals = Object.values(leaders.find(l => l.id === value.id)?.leader_calendars || [])
      .map(cal => cal.calendar);
    const newCals =  assignedCalendars.map(calId => ({ id: calId, associate: true }));
    prevCals.forEach(calId => {
      if (!newCals.find(c => c.id === calId)) {
        newCals.push({ id: calId, associate: false });
      }
    });
    await onAddEditExec({ ...newValues, calendars: newCals });
    setAddingExec(false);
    setEditingExec(null);
    reset({ firstName: '', lastName: '', email: '', calendars: [] });
  };

  const handleCancel = () => {
    setAddingExec(false);
    setEditingExec(null);
    reset({ firstName: '', lastName: '', email: '', calendars: [] });
  };

  const handleFinish = async () => {
    setLoading(true);
    await onFinish();
    setLoading(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
      <StepHeader
        title='Map Executives'
        text='Let us know the names of the people you support and connect their calendars.'
      />

      <Box display="flex" flexDirection="column" width="100%" flex={1} alignItems="center">
        <Box display="flex" flexDirection="column" gap={3} marginTop={4} width="100%" maxWidth="580px" flex={1}>
          <Box display='flex' flexDirection='column' width='100%'>
            <Typography variant='h4'>Executives</Typography>
            <Typography variant='body1' color={colors.black800}>
              Add your executives and their calendars. You can add more executives later.
            </Typography>
          </Box>
          {leaders.filter(l => l.id !== user.profile.user_leader && !l.is_shared).map(leader => {
            const value = {
              firstName: leader.first_name,
              lastName: leader.last_name,
              email: leader.email,
              calendars: calendars.filter(ca => ca.leaders.includes(leader.id)).map(ca => ca.id),
              isUser: false
            };
            return (
              <ExecutiveListItem
                key={leader.id}
                executive={value}
                onEdit={(() => handleEdit(leader.id, value))}
                onDelete={() => onDeleteExec(leader.id)}
              />
            );
          })}

          {leaders.length >= 1 && (
            <CabButton
              buttonType="tertiary"
              color="accent"
              icon={<CabIcon Icon={IoAdd} />}
              sx={{ width: 200 }}
              onClick={() => setAddingExec(true)}
            >
              Add New Executive
            </CabButton>
          )}

          <Box display='flex' flexDirection='column' width='100%'>
            <Typography variant='h4'>My Calendars</Typography>
            <Typography variant='body1' color={colors.black800}>
              Add or edit your own personal calendars.
            </Typography>
          </Box>
          {leaders.filter(l => l.id === user.profile.user_leader).map(leader => {
            const value = {
              firstName: leader.first_name,
              lastName: leader.last_name,
              email: leader.email,
              calendars: calendars.filter(ca => ca.leaders.includes(leader.id)).map(ca => ca.id),
              isUser: true
            };
            return (
              <ExecutiveListItem
                key={leader.id}
                executive={value}
                onEdit={(
                  // don't allow editing for the user_leader if they don't have any grants (or the modal will be empty)
                  !hasCalendarGrants
                    ? undefined
                    : () => handleEdit(leader.id, value)
                )}
              />
            );
          })}

          {leaders.length === 0 && (
            <>
              <AddEditExecutive
                calendars={calendars}
                onGetEmailCalendars={onGetEmailCalendars}
                oauth_grant_details={oauth_grant_details}
                form={execForm}
                user={user}
              />

              <CabButton
                onClick={handleFormSubmit(handleSave)}
                size="large"
                sx={{ width: '100%', marginTop: 2 }}
                disabled={!isValid}
              >
                Save Executive
              </CabButton>
            </>
          )}

        </Box>
      </Box>

      {leaders.length > 0 && (
        <Box display="flex" width="100%" justifyContent="center" marginTop={6}>
          <CabButton
            size="large"
            endIcon={loading
              ? <CabSpinner scale={1} color="info" sx={{ marginLeft: -1 }} />
              : <CabIcon size="small" Icon={IoArrowForward} />}
            onClick={handleFinish}
            disabled={loading}
          >
            {loading ? '' : (buttonText || 'Next')}
          </CabButton>
        </Box>
      )}

      {leaders.length > 0 && (
        <CabModal
          title={addingExec ? "Add New Executive" : editingExec !== user.profile.user_leader ? "Edit Executive" 
            : 'Edit My Calendars'}
          closeIcon
          open={addingExec || !!editingExec}
          onClose={() => handleCancel()}
          actionButtons={(
            <>
              <CabButton
                onClick={() => handleCancel()}
                buttonType="secondary"
                disabled={isSubmitting}
              >
                Cancel
              </CabButton>
              <CabButton
                onClick={handleFormSubmit((v) => handleSave({ ...v, id: editingExec || undefined }))}
                disabled={!isValid || isSubmitting}
              >
                {isSubmitting
                  ? <CabSpinner scale={1} />
                  : (addingExec ? "Add Executive" : "Save")}
              </CabButton>
            </>
          )}
        >
          <AddEditExecutive
            calendars={calendars}
            onGetEmailCalendars={onGetEmailCalendars}
            oauth_grant_details={oauth_grant_details}
            form={execForm}
            hideName={editingExec === user.profile.user_leader}
            user={user}
          />
        </CabModal>
      )}

    </Box>
  );
};

export default ExecutiveCalendarStep;
